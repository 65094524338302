import React from 'react';
import './Career.css';
import Form from '../../components/Form/Form';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Career = () =>{

    return(
<div>
    <div className="careerContainer">
        <div className="career">
        <h1>Careers</h1>
        <p>Do you wish to work with Ilam Thalir?<br/>Do you wish to become a Volunteer?<br/>Do you wish to take part in an Internship or something long term?<br/><br/>Contact us through the form below to get started.</p><br/><br/>
        </div>
    </div>

    <div className="volunteerContainer">
        <div className="volunteer">
        <h1 className='volunteerTitle'>For Volunteers</h1>
        <p className='volunteerText'>To join Ilam Thalir as a Volunteer, please fill a Google Form from the link below.<br></br></p>
        <Link to="https://forms.gle/oYz5aDgvwRQPsR9J9" target="_blank" rel="noopener noreferrer"><button>Get Involved &nbsp;<FontAwesomeIcon icon="arrow-right"/></button></Link>
        </div>
    </div>

    <div className="internshipContainer">
        <div className="internship">
        <h1>For Internships</h1>
        <p>To support Ilam Thalir as an Intern, please fill the following form below.</p>
        <Link to="https://forms.gle/Fvhh2roS7MrWUuEr7" target="_blank" rel="noopener noreferrer"><button>Support us &nbsp;<FontAwesomeIcon icon="arrow-right"/></button></Link>
        </div>
    </div>

    <div className="positionContainer">
        <div className="position">
        <h1>For Positions</h1>
        <p>To become an administrative member of Ilam Thalir, please contact us at <a className='emailContent' href="mailto:ilamthalirss@gmail.com" target="_blank" rel="noopener noreferrer">ilamthalirss@gmail.com</a> or by filling the form given below</p>
        </div>
    </div>

    <div className='formContainer'>
        <div className='form'>
            <h1>Contact Form</h1>
            <p>Enter your basic details, a brief introduction and attach your CV to get started. </p>
            <Form/>
        </div>
    </div>


</div>
    )
}

export default Career;