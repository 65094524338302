import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';

const Contact = () =>{
    return(
<div>
    <div className="contactContainer">
        <div className="contact">
        <h1>Contact us</h1>
        <p>You can find and contact us through the following!</p>
        </div>
    </div>
    
    <div className='mapContainer'>
        <div className='map'>
            <h1 className='mapTitle'>Directions</h1>
            <p className='mapText'>You can find us at the co-ordinates below if you wish to visit us!</p>
            <div className='mapEmbed'>
                <div id="google-maps-canvas">
                    <iframe title='googleMaps' frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Ilam+Thalir+Social+Service+Trust,+Masakalipalayam+Road,+Lalbahadur+Nagar,+Lakshmipuram,+Coimbatore,+Tamil+Nadu,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
                </div>
                <br></br>
            </div>
        </div>
    </div>
    <div className='addressContainer'>
        <div className='address'>
            <h1 className='addressTitle'>Address</h1>
            <p className='addressText'>Do you prefer an address? Do you wish to mail us? You can find our address below!</p>
            <p className='addressContent'>78, Lal Bagdhur Nagar Street<br/>2, Masakalipalayam Road, Lakshmipuram<br/>Coimbatore, Tamil Nadu<br/>PIN: 641004</p>
        </div>
    </div>
    <div className='emailContainer'>
        <div className='email'>
            <h1 className='emailTitle'>E-mail</h1>
            <p className='emailText'>If you have any enquiries or proposals to make this world a better place, do mail us at <a className='emailContent' href="mailto:contact@ilamthalir.in">contact@ilamthalir.in</a> or <a className='emailContent' href="mailto:ilamthalirss@gmail.com">ilamthalirss@gmail.com</a></p>
        </div>
    </div>
    <div className='phoneContainer'>
        <div className='phone'>
            <h1 className='phoneTitle'>Call us</h1>
            <p className='phoneText'>If you wish to talk to Ilam Thalir, you can do so by calling to <a className='phoneContent' href="tel:+917502416201">+91 7502416201</a></p>
        </div>
    </div>
    <div className='reachContainer'>
        <div className='reach'>
            <h1 className='reachTitle'>Reach us</h1>
            <p className='reachText'>Do you wish to reach us through Social Media? Click any of the links below to get started!</p>
            <div className='reachIcons'>
            <Link to="https://instagram.com/ilamthalir" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="50px" color="#DD2A7B" icon={faInstagram}/></Link><Link to="https://www.linkedin.com/company/ilam-thalir-social-service-trust/posts/?feedView=all" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="50px" color="#0077b5" icon={faLinkedin}/></Link><Link to="https://twitter.com/IlamThalirTrust" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="50px" color="#08A0E9" icon={faTwitter}/></Link><Link to="https://wa.me/+917502416201" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="50px" color="#075E54" icon={faWhatsapp}/></Link>
            </div>
            <br></br>
        </div>
    </div>

</div>
    )
}

export default Contact;