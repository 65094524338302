import React from 'react';
import './Vision.css';
import Team from '../../components/Team/Team';

const Vision = () =>{
    return(
<div>
    <div className="visionContainer">
        <div className="vision">
        <h1>Our Vision</h1>
        <p>Ilam Thalir Social Service Trust is a registered Non-Governmental Organization<br/>operating all over Tamil Nadu to support local communities<br/>by serving, donating and educating the masses! <br></br><br></br>We have so far conducted Medical Camps, Food & Medicine Drives, Disaster Relief Drives,<br></br>Awareness Programs, Recycling Drives and Tree Planting across Tamil Nadu!</p><br/><br/>
        </div>
    </div>

    <div className='teamContainer'>
        <div className='team'>
            <h1 className='teamTitle'>The Team</h1>
            <p className='teamText'>Meet our team that makes Ilam Thalir possible.</p>
            <Team/>
        </div>
    </div>

    <div className='historyContainer'>
        <div className='history'>
            <h1 className='historyTitle'>Our History</h1>
            <p className='historyText'>We've come a long way since our beginnings. Learn how we evolved to what we are now.</p>
        </div>
    </div>

    <div className="timelineContainer">
        <div className="timeline">
            <div className="timelineGroup">
                <ul className="milestones">

                    <li className="milestone-item">
                        <div className="timestamp">
                           2023<br></br> 
                        </div>
                        <div className="item-highlight">Conducted our very first Medical Camp in Tirupur in collaboration with 5 Hospitals</div>
                    </li>
                    <li className="milestone-item">
                        <div className="timestamp">
                           2023<br></br> 
                        </div>
                        <div className="item-highlight">Ilam Thalir enters its  5th year of operations!</div>
                    </li>
                    <li className="milestone-item">
                        <div className="timestamp">
                           2022<br></br> 
                        </div>
                        <div className="item-highlight">Conducted Awarness Campaigns for School Students and started offering Internships</div>
                    </li>

                    <li className="milestone-item">
                        <div className="timestamp">
                           2021<br></br> 
                        </div>
                        <div className="item-highlight">Ilam Thalir registered as a Non-Governmental Organization.</div>
                    </li>

                    <li className="milestone-item">
                        <div className="timestamp">
                            2021<br></br> 
                        </div>
                        <div className="item-highlight">Conducted our Diwali Service with massive Community Support</div>
                    </li>


                    <li className="milestone-item">
                        <div className="timestamp">
                           2020<br></br> 
                        </div>
                        <div className="item-highlight">Ilam Thalir expanded across Tamil Nadu into various districts.</div>
                    </li>

                    <li className="milestone-item">
                        <div className="timestamp">
                           2019<br></br> 
                        </div>
                        <div className="item-highlight">Conduct our 1st Field Service in Ooty by providing supplies to Landslide struck areas.</div>
                    </li>

                    <li className="milestone-item">
                        <div className="timestamp">
                           2019<br></br> 
                        </div>
                        <div className="item-highlight">Ilam Thalir began operations.</div>
                    </li>



                </ul>
            </div>
        </div>
    </div>


</div>
    )
}

export default Vision;