import React from 'react';
import './NotFound.css';

const NotFound = () =>{
    return(
<div>
    <div className="notFoundContainer">
        <div className="notFound">
        <h1>404</h1>
        <p>This page doesn't exist, removed or not available.</p><br/><br/>
        </div>
    </div>

</div>
    )
}

export default NotFound;