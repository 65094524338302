import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../Form/Form.css'

export const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_dn6txiv', 'template_ody8fbb', form.current, 'gZI-zHMrNZWom0Hx1')
    e.target.reset();
  };

  return (
  <div className='formContainer'>
  <form ref={form} onSubmit={sendEmail}>
    <div className='textContainer'>
    <label for='username'>Name</label>
    <input type='text'  id='username' name='user_name' placeholder='Enter your Name' required autocomplete="on"></input>
    <br></br>
    </div>
    <div className='textContainer'>
    <label for='email'>Email</label>
    <input type='text' name='user_email' placeholder='Enter your Email' required autocomplete="on"></input>
    <br></br>
    </div>
    <div className='textboxContainer'>
    <label for='introduction'>Introduce yourself</label>
    <textarea type='text' id='introduction' name='user_introduction' placeholder='Share us a little about yourself...' required></textarea>
    <br></br>
    </div>
    <div className='textboxContainer'>
    <label for='reason'>Why should we hire you?</label>
    <textarea id='reason' name='user_reason' placeholder='Share us why hiring you will be a net positive...' required></textarea>
    <br></br>
    </div>
    <div className='textContainer'>
    <label for='link'>Resume/CV</label>
    <input id='link' type='text'name='user_pdf' placeholder='Share viewable Google Drive link to Resume' required></input>
    </div>
    <br></br>
    <div className='submitContainer'>
    <input name='submitBttn' type='submit'></input>
    </div>
    <br></br>
  </form>
  </div>
  );
};

export default Form;