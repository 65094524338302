import React from 'react';
import './Events.css';
import Articles from '../../components/Articles/Articles';


const Events = () =>{
    const slug = 'events'
    const eventPage = 'eventPage'
    const postPerPage = 50;
    const eventQuery = `https://wp.ilamthalir.in/wp-json/acf/v3/events?per_page=${postPerPage}/`;

    return(
<div>
    <div className="eventsContainer">
        <div className="events">
        <h1>Events</h1>
        <p>Ilam Thalir Social Service Trust has conducted various events over the years.<br/>In order to preserve all the progress that has been done thus far,<br></br>You can find our previous work and anything new here!</p><br/><br/>
        </div>
    </div>
    <Articles queryString={eventQuery} pageClass={eventPage} slugName={slug}/>
</div>
    )
}

export default Events;