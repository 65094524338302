import React from 'react';
import Articles from '../../components/Articles/Articles';
import './Blog.css';

const Blog = () =>{
    const slug = 'blog'
    const blogPage = 'blogPage'
    const postPerPage = 50;
    const blogQuery = `https://wp.ilamthalir.in/wp-json/acf/v3/blogs?per_page=${postPerPage}/`;
    
    return(
<div>
    <div className="blogContainer">
        <div className="blog">
        <h1>Blog</h1>
        <p>Ilam Thalir Social Service Trust has over the course of it's existence has shared various Articles, News and Flyers! <br/>You can find these articles along with any new content such as opinion pieces here.<br/>On our very own blog!</p><br/><br/>
        </div>
    </div>
<Articles queryString={blogQuery} pageClass={blogPage} slugName={slug}/>

</div>
    )
}

export default Blog;