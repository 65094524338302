import React from 'react';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useFetch from '../../hooks/useFetch'
import './Articles.css';



const  Articles = ({queryString, slugName, pageClass}) =>{
    let {loading, data, error} = useFetch(queryString);
    if (loading) return <div className='loadIcon'><FontAwesomeIcon  color="green" fontSize="60px" icon="spinner" spin/></div>
    if (error) return <div className='errorIcon'><FontAwesomeIcon  color="red" fontSize="60px" icon="triangle-exclamation" beatFade/><p>We could not fetch the requested data.<br></br>Please check back later to see if the issue has been resolved.</p></div>

    return(
        <div className={`articleWrap ${pageClass}`}>
            {data.map((article)=>
            <Link className='articleLink' key={article.acf.slug_id} to={`/${slugName}/${article.acf.slug_id}`}>
            <div className='articlePost'>
                <img className='postImage' src={`${article.acf.cover}`} alt={article.acf.cover_alt}/>
                <div className='postBody'>
                    <div className='primaryBody'>
                        <div className='postTitle'>
                            <h3>{article.acf.title}</h3>
                        </div>
                    <div className='postText'>
                        <ReactMarkdown>{article.acf.description.slice(0,100)+'...'}</ReactMarkdown>
                    </div>
                </div>
                    <div className='secondaryBody'>
                        <div className='postCategory'>
                            <p>{article.acf.category}</p>
                        </div>
                    </div>
                </div>
            </div>
            </Link>


            )}


        </div>
    )
}

export default Articles;