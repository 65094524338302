import './Navbar.css';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../images/ilamlogo.png';
import whiteLogo from '../../images/whiteilamlogo.png'
import { useState } from 'react';

const Navbar =()=>{

    const [menuState,setmenuState] = useState(false);

    return(
<div className={menuState? "navMenu open":"navMenu"}>
<Link className="logoLink" to="/"><img height="60px" width="60px" src={logo} alt="Ilam Thalir Logo"></img></Link>
<nav className='navBar'>
<Link className="navLinks linkLines" to="/">About us</Link>
<Link className="navLinks linkLines" to="/vision"> Our Vision</Link>
<Link className="navLinks linkLines" to="/events">Events</Link>
<Link className="navLinks linkLines" to="/blog">Blog</Link>
<Link className="navLinks linkLines" to="/contact">Contact us</Link>
<Link className="navLinks linkLines" to="/careers">Careers</Link>
</nav>
<Link className="donateBttn navLinks linkLines" to="/donate">Donate</Link>
<div className={menuState? "mobileMenu open": "mobileMenu"} onClick={() => {setmenuState(!menuState)}}>
<label>
<span></span>
<span></span>
<span></span>
</label>
<FontAwesomeIcon className={menuState? "closeIcon open": "closeIcon"} icon="fa-solid fa-xmark"/>
<nav className={menuState? "mobileNavBar open": "mobileNavBar"}>
<Link className="mobileNavLinks linkLines whiteLogoLink" to="/"><img height="60px" width="60px" src={whiteLogo} alt="Ilam Thalir Logo"></img></Link>
<Link className="mobileNavLinks linkLines" to="/">About us</Link>
<Link className="mobileNavLinks linkLines" to="/vision"> Our Vision</Link>
<Link className="mobileNavLinks linkLines" to="/events">Events</Link>
<Link className="mobileNavLinks linkLines" to="/blog">Blog</Link>
<Link className="mobileNavLinks linkLines" to="/contact">Contact us</Link>
<Link className="mobileNavLinks linkLines" to="/careers">Careers</Link>
<Link className="mobileNavLinks linkLines" to="/donate">Donate</Link>
</nav>
</div>
</div>

    )
}
export default Navbar;