import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Articles from '../../components/Articles/Articles';

const About = () =>{
    const aboutPage='aboutPage'
    const blogSlug = 'blog'
    const blogLimit = 2;
    const blogLimitedQuery = `https://wp.ilamthalir.in/wp-json/acf/v3/blogs?per_page=${blogLimit}/`;
    const eventSlug = 'events'
    const eventLimit = 2;
    const eventLimitedQuery = `https://wp.ilamthalir.in/wp-json/acf/v3/events?per_page=${eventLimit}/`;


    return(
        <div>

        <div className='welcomeBanner' role='img' aria-label="'Kid Laughing Green' Photo by Vigneshwar Rajkumar on Unsplash">
        <br></br>
        <h2>Only a life lived for others,<br></br>is a life worthwhile.</h2>  
        <br></br>
      </div>

      <br></br>
     <div className="aboutUsContainer">
        <div className="aboutUs">
        <h1 className='titleText'>About us</h1>
        <p>Ilam Thalir Social Service Trust is a registered Non-Governmental Organization<br/>operating in all major cities in Tamil Nadu including Coimbatore, Chennai and Madurai.<br/> We support local communities by serving, donating and educating the masses!</p><br/><br/>
        <Link to="/vision"><button>Learn more &nbsp;<FontAwesomeIcon icon="arrow-right"/></button></Link>
        </div>
    </div>

    <br></br>

    <div className='ourPrinciples'>
    <h1>Our Principles</h1>
    <p>At Ilam Thalir, Our work revolves around our three primary guiding principles. To donate blood to save lives,<br/> To revitalize communities with urban reforestation and promoting water conservation<br/>But that is not all we do.</p>
    </div>

    <br></br>

    <div className='principles'>
    <div className="ourPrinciplesContainer" >
      <div className="donateRed"><FontAwesomeIcon fontSize="90px"icon="droplet"/>
      <p>Donate<br/>Red</p>
      </div>

      <div className="spreadGreen"><FontAwesomeIcon fontSize="90px" icon="seedling"/>
      <p>Spread<br/>Green</p>
      </div>
    
      <div className="saveBlue"> <FontAwesomeIcon fontSize="90px" icon="faucet-drip"/>
      <p>Save<br/>Blue</p>
      </div>
    </div>
    </div>



    <div className='bootstrap'>
    <h1 className='bootstrapTitle'>We are a Bootstrap Initiative!</h1>
    <p className='bootstrapText'>Ilam Thalir is almost exclusively ran through self-funding. We rely on our members to power goodness!<br/>
    If you wish to donate, you can begin here!<br/><br/><Link to="/donate"><button>Donate now</button></Link><br/></p>
    </div>

    <div className='eventsHome'>
      <h1 className='eventsTitle'>Events</h1>
      <p className='eventsText'>Ilam Thalir regularly performs various activities and events for the benefit of all.<br/>Find out what's happening right now!</p>
      <Articles queryString={eventLimitedQuery} pageClass={aboutPage} slugName={eventSlug}/>
      <Link to="/events"><button>Find more &nbsp;<FontAwesomeIcon icon="arrow-right"/></button></Link>
    </div>

    <div className='blogsHome'>
      <h1 className='blogsTitle'>Blogs</h1>
      <p className='blogsText'>Be it to bring awareness or discover stories of the people that make Ilam Thalir possible...<br/>Find here the varied voices of Ilam Thalir.</p>
      <Articles queryString={blogLimitedQuery} pageClass={aboutPage} slugName={blogSlug}/>
      <Link to="/blog"><button>Read more &nbsp;<FontAwesomeIcon icon="arrow-right"/></button></Link>
    </div>

    <br></br>


    </div>
    )
}

export default About;