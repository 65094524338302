import React from 'react';
import './Content.css';
import useFetch from '../../hooks/useFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

const Content = ({queryString}) =>{
    const {id} = useParams();
    let {loading, data, error} = useFetch(`${queryString}=${id}`)
    if (loading) return <div className='loadIcon'><FontAwesomeIcon  color="green" fontSize="60px" icon="spinner" spin/></div>
    if (error) return <div className='errorIcon'><FontAwesomeIcon  color="red" fontSize="60px" icon="triangle-exclamation" beatFade/></div>
    let blog = data[0];

    return(
<div>
    <div className="contentContainer">
        <div className="content">
        <h1>{blog.acf.title}</h1>
        <img className='coverBanner' src={`${blog.acf.cover}`} alt={blog.acf.cover_alt}></img>
        <p className='coverCaption'>Caption: {blog.acf.cover_alt}</p>
        <ReactMarkdown>{blog.acf.content}</ReactMarkdown><br/><br/>
        { typeof blog.acf.images== "boolean" ? <br/> : [blog.acf.images?.url].map((images)=>
        <img className='contentImages' src={`${images}`} alt={blog.acf.images.alt}/>
        )}
        </div>

        
    </div>


</div>
    )
}

export default Content;