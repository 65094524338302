import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';
import { faInstagram, faLinkedin, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Footer =()=>{
    return(
<footer>
<br></br>
<div className='brandIcons'>
<Link to="https://maps.app.goo.gl/hWxeQWc1RQUkjzbT9" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="25px" icon={faMapMarkerAlt}/></Link><Link to="https://instagram.com/ilamthalir" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="25px" icon={faInstagram}/></Link><Link to="https://www.linkedin.com/company/ilam-thalir-social-service-trust/posts/?feedView=all" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="25px" icon={faLinkedin}/></Link><Link to="https://twitter.com/IlamThalirTrust" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="25px" icon={faTwitter}/></Link><Link to="https://wa.me/+917502416201" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon fontSize="25px" icon={faWhatsapp}/></Link>
</div>
<p>Copyright © 2023 Ilam Thalir. All Rights Reserved.<br></br>Ilam Thalir Social Service Trust [190/2021] is a registered Non Governmental Organization in Tamil Nadu, India.</p>
</footer>
    )
}
export default Footer;