import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import UPI from '../../images/upiqr.png';
import './Donate.css';

const Donate = () =>{
    return(
<div>
    <div className="donateContainer">
        <div className="donate">
        <h1>Donate</h1>
        <p>Ilam Thalir is almost exclusively ran through self-funding. We rely on our members to power goodness!<br/>You can also donate by scanning the UPI QR code below or by paying from the website!</p><br/><br/>
        </div>
    </div>

    <div className='upiDonateContainer'>
        <div className='upiDonate'>
        <h2>Ilam Thalir Social Service Trust</h2>
        <img className='upiImage' src={UPI} alt="UPI"></img>
        <p className='vpa'>VPA: wickethari-1@okaxis <FontAwesomeIcon icon={faCopy} onClick={() => {navigator.clipboard.writeText('wickethari-1@okaxis'); navigator.vibrate(100); document.querySelector('.vpa').style.fontWeight = 'bolder'; window.setTimeout(function (){document.querySelector('.vpa').style.fontWeight = ''} , 800); }}/></p>
        <h3>Scan and Pay with any UPI App</h3>
        <div className='govUPI'>
            <img className='BHIM' src="https://upload.wikimedia.org/wikipedia/en/thumb/6/65/BHIM_SVG_Logo.svg/320px-BHIM_SVG_Logo.svg.png" alt="BHIM" height="40px"></img>
            &nbsp; &nbsp; &nbsp;
            <img className='UPI' src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/UPI-Logo-vector.svg/320px-UPI-Logo-vector.svg.png" alt="UPI" height="40px"></img>
        </div>
        <br></br>
        <br></br>
        <div className='privateUPI'>
            <img className='gPay' src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Google_Pay_Logo.svg/320px-Google_Pay_Logo.svg.png' alt="GPay" height="30px"></img>&nbsp; &nbsp; &nbsp;
            <img className='phonePe' src='https://pbs.twimg.com/profile_images/1615271089705463811/v-emhrqu_400x400.png' alt="PhonePe" height="30px"></img>&nbsp; &nbsp; &nbsp;
            <img className='payTM' src='https://upload.wikimedia.org/wikipedia/commons/4/42/Paytm_logo.png' alt="PayTM" height="30px"></img>&nbsp; &nbsp; &nbsp;
            <img className='amazonPay' src='https://zeevector.com/wp-content/uploads/Amazon-Pay-Logo-Colour.png' alt="Amazon Pay" height="30px"></img>
        </div>
        <br></br>
        <br></br>
        <Link to="/webdonate"><button>Direct Donate &nbsp;<FontAwesomeIcon icon="arrow-right"/></button></Link>
        <p>Direct Donate allows you to donate directly from the website!</p>
        </div>
    </div>

</div>
    )
}

export default Donate;