import { Route, Routes } from 'react-router-dom';
// import useFetch from './hooks/useFetch'
import './App.css';
import Navbar from './components/Navbar/Navbar';
import About from './pages/About/About';
import Vision from './pages/Vision/Vision';
import Events from './pages/Events/Events';
import Blog from './pages/Blog/Blog';
import Content from './pages/Content/Content';
import Contact from './pages/Contact/Contact';
import Career from './pages/Career/Career';
import Donate from './pages/Donate/Donate';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faDroplet, faSeedling, faFaucetDrip, faArrowRight, faSpinner,faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import Footer from './components/Footer/Footer';
import Maintenance from './pages/Maintenance/Maintenance';
import NotFound from './pages/Not Found/NotFound';
library.add(fab, faDroplet, faSeedling, faFaucetDrip, faArrowRight, faSpinner, faTriangleExclamation, faXmark)

function App() {
   const eventQuery = 'https://wp.ilamthalir.in/wp-json/wp/v2/events?slug';
   const blogQuery = 'https://wp.ilamthalir.in/wp-json/wp/v2/blogs?slug';
  return (
    <div className='App'>
    <Navbar/>
      <Routes>
        <Route path="*" element={<NotFound/>}></Route>
        <Route path="/" element={<About/>}></Route>
        <Route path="/vision" element={<Vision/>}></Route>
        <Route path="/events" element={<Events/>}></Route>
        <Route path="/events/:id" element={<Content queryString={eventQuery}/>}></Route>
        <Route path="/blog" element={<Blog/>}></Route>
        <Route path="/blog/:id" element={<Content queryString={blogQuery}/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/careers" element={<Career/>}></Route>
        <Route path="/donate" element={<Donate/>}></Route>
        <Route path="/webdonate" element={<Maintenance/>}></Route>

      </Routes>
    <Footer/>
    </div>
  );
}

export default App;
