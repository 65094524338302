import React, { useEffect } from 'react';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './Team.css'; 
import data from './team.json';

const Team = () => {
  useEffect(() => {
    import('@splidejs/splide').then(({ default: Splide }) => {
      const splide = new Splide('#team-slider', {
        type: 'loop',
        autoplay: true,
        drag: 'free',
        interval: 3000,
        perMove:1,
        perPage: 1,
        fixedWidth: 220,
        fixedHeight: 340, 
        focus:'start',
        arrows:false,
        pagination: false,
        breakpoints: {
          600: {
            focus:'center',
            perPage: 1,
          },
          800: {
            focus:'center',
            perPage: 2,
          },
        },
      });

      splide.mount();

      return () => {
        splide.destroy();
      };
    });
  }, []);

  return (
    <div id="team-slider" className="splide custom-splide">
      <div className="splide__track">
        <ul className="splide__list">
          {data.map((team) => (
            <li key={team.id} className="splide__slide teamPost">
              <img className="memberImage" src={`${team.image}`} alt={team.image_alt} />
              <div className="memberBody">
                <div className="membersBody">
                  <h3>{team.name}</h3>
                  <p>{team.title}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Team;
