import React from 'react';
import './Maintenance.css';

const Maintenance = () =>{
    return(
<div>
    <div className="maintenanceContainer">
        <div className="maintenance">
        <h1>Coming Soon!</h1>
        <p>This page is currently under Construction or Maintenance, kindly check back later to see if the page is available.</p><br/><br/>
        </div>
    </div>

</div>
    )
}

export default Maintenance;